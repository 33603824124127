import React, { useState, useEffect } from 'react';
import {
  Upload,
  ArrowRight,
  FileText,
  Target,
  Clock,
  Zap,
  X,
  Eye,
  Download
} from 'lucide-react';
import Checkout from '../PaymentGateway/Checkout';
import { load } from "@cashfreepayments/cashfree-js";
import axios from 'axios';
import { baseURL } from '@/utility/api';



import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const FileUploadForm = () => {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [cashfree, setCashfree] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [info, setInfo] = useState({
    id: 101,
    name: '',
    email: '',
    phone_number: '',
    type: 'service',
    notes: '',
  });

  const navigate = useNavigate();

  // Initialize Cashfree SDK once
  useEffect(() => {
    const initializeSDK = async () => {
      const sdk = await load({ mode: 'production' }); // Change to 'production' in production mode
      setCashfree(sdk);
    };
    initializeSDK();
  }, []);


  const handleSubmit = async() => {
    // const {error,setError} = useContext(MainContext)
      setIsFormSubmitted(true);

     
        if(info.name==="" || info.email==="" || info.phone_number===""){
            toast.error('Please fill all the fields');
            return;
        }
        if(info.phone_number.length!==10){
            toast.error('Please enter a valid phone number');
            return;
        }
        if(!file){
            toast.error('Please upload a file');
            return;
        }
        
        const formData = new FormData();
        formData.append("file", file); 
        formData.append("id", info.id);
        formData.append("name", info.name);
        formData.append("email", info.email);
        formData.append("phone_number", info.phone_number);
        formData.append("type", info.type);
        formData.append("note", info.notes);
                
        
        const response = await axios.post(`${baseURL}/payment/create-order-with-file`, formData, {
            headers: { 
                'Content-Type': 'multipart/form-data'  
            }
        });

        console.log("Response:", response.data);
        
        
        if(response.data.message){
            toast.error(response.data.message);
            return;
        }
        const sessionId=response.data.sessionId;
        const orderId=response.data.orderId;
        let checkoutOptions = {
            paymentSessionId: sessionId,
            redirectTarget: "_modal",
        };
        cashfree.checkout(checkoutOptions).then((result) => {
            if(result.error){
                // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
                console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                console.log(result.error);
            }
            if(result.redirect){
                // This will be true when the payment redirection page couldnt be opened in the same window
                // This is an exceptional case only when the page is opened inside an inAppBrowser
                // In this case the customer will be redirected to return url once payment is completed
                console.log("Payment will be redirected");
            }
            if(result.paymentDetails){
                // This will be called whenever the payment is completed irrespective of transaction status
               
                navigate(`/confirmation-page/${orderId}`);
                console.log("Payment has been completed, Check for Payment Status");
                console.log(result.paymentDetails.paymentMessage);
            }
        });
   
  }

  useEffect(() => {
    setFormVisible(true);
    if (file) {
      const timer = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            return 100;
          }
          return prev + 2;
        });
      }, 50);
      return () => clearInterval(timer);
    }
  }, [file]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setUploadProgress(0);
      

      if (selectedFile.type === 'application/pdf') {
        const url = URL.createObjectURL(selectedFile);
        setFilePreview(url);
      } else {
        setFilePreview(null);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };
  

  return (
    <div className="min-h-screen bg-gray-950  flex items-center justify-center">
      <div 
        className={`max-w-2xl w-full transition-all duration-1000 transform 
          ${formVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/5 to-blue-500/5 rounded-3xl blur-3xl"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-blue-500/10 rounded-3xl rotate-2"></div>

        <div className="relative bg-gray-900/90 backdrop-blur-xl p-8 rounded-3xl border border-gray-800 shadow-2xl">
          <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
            <div className="space-y-4">
            {['name', 'email', 'phone_number'].map((field) => {
                const errorMessages = {
                  name: 'Name must be at least 3 characters long.',
                  email: 'Please enter a valid email address.',
                  phone_number: 'Phone number must be 10 digits long.',
                };

                const isValid = {
                  name: info.name.length >= 3,
                  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(info.email),
                  phone_number: /^[0-9]{10}$/.test(info.phone_number),
                };

                const handleBlur = (field) => {
                  if (!isValid[field]) {
                    toast.error(errorMessages[field]);
                  }
                };

                return (
                  <div key={field}>
                    <label className="block text-sm font-medium mb-2 text-gray-300">
                      {field.replace('_', ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
                    </label>
                    <input
                      type={field === 'email' ? 'email' : field === 'phone_number' ? 'tel' : 'text'}
                      name={field}
                      value={info[field]}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur(field)}
                      onInput={(e) => {
                        if (field === 'phone_number') {
                          e.target.value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        }
                      }}
                      className={`w-full px-4 py-3 bg-gray-800/50 rounded-xl border ${
                        ('border-gray-700' )}
                      } focus:border-purple-500 focus:ring-2 focus:ring-purple-500/20 transition-all hover:border-gray-600`}
                      placeholder={`Enter your ${field.replace('_', ' ')}`}
                      maxLength={field === 'phone_number' ? 10 : undefined}
                    />
                    {isFormSubmitted && !isValid[field] && (
                      <p className="text-sm text-red-500 mt-1">{errorMessages[field]}</p>
                    )}
                  </div>
                );
              })}


              <div>
                <label className="block text-sm font-medium mb-2 text-gray-300">Resume Upload</label>
                <div className="relative">
                  <input
                    type="file"
                    className="hidden"
                    id="resume-upload"
                    accept=".pdf"
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="resume-upload"
                    className="w-full px-4 py-4 flex flex-col items-center justify-center bg-gray-800/50 
                      rounded-xl border-2 border-dashed border-gray-700 hover:border-purple-500 
                      cursor-pointer transition-all group"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                  >
                    <Upload className={`w-8 h-8 text-purple-400 mb-2 transition-transform duration-300
                      ${isHovering ? 'transform -translate-y-1' : ''}`} />
                    <span className="text-sm text-gray-400 group-hover:text-purple-400 transition-colors">
                      Click to browse
                    </span>
                    <span className="text-xs text-gray-500 mt-1">
                      Supported formats: PDF
                    </span>
                  </label>
                </div>
                {file && (
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-300">
                        <FileText className="w-4 h-4 inline mr-2" />
                        {file.name}
                      </span>
                      <span className="text-gray-400">
                        {(file.size / 1024).toFixed(2)} KB
                      </span>
                    </div>
                    <div className="relative h-2 bg-gray-800 rounded-full overflow-hidden">
                      <div 
                        className="absolute h-full bg-gradient-to-r from-purple-500 to-blue-500 transition-all duration-300"
                        style={{ width: `${uploadProgress}%` }}
                      />
                    </div>
                    <div className="flex justify-between text-xs text-gray-500">
                      {uploadProgress === 100 ? <span>Uploaded</span> : <span>Uploading...</span>}
                      <span>{uploadProgress}%</span>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-2 text-gray-300">Additional Notes</label>
                <textarea
                  className="w-full px-4 py-3 bg-gray-800/50 rounded-xl border border-gray-700 
                    focus:border-purple-500 focus:ring-2 focus:ring-purple-500/20 transition-all 
                    h-32 resize-none"
                  name="notes"
                  placeholder="Any specific areas you'd like us to focus on?"
                  onChange={(e) => setInfo({ ...info, notes: e.target.value })}
                />
              </div>
            </div>

            <button
              type="button"
              onClick={handleSubmit}
              className="w-full bg-gradient-to-r from-purple-600 to-blue-600 
                hover:from-purple-700 hover:to-blue-700 py-4 rounded-xl font-medium 
                transition-all flex items-center justify-center gap-2 group
                relative overflow-hidden"
            >
              <span className="relative z-10 flex items-center gap-2">
                Proceed to Checkout
                <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </span>
              <div className="absolute inset-0 bg-gradient-to-r from-purple-700 to-blue-700 
                transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
            </button>
          </form>

          {/* {showCheckout && <Checkout info={info} file={file} />} */}
        </div>
        <ToastContainer
      position = {"bottom-right"}
      autoClose={5000}
limit={1}
closeOnClick/>
      </div>
      
    </div>
  );
};

export default FileUploadForm;
