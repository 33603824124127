import Hero from '@/components/HeroSection/Hero'
import { MarqueeDemo } from '@/components/Testimonials/Testimonials'
import NumberCounter from '@/components/NumberCounter/NumberCounter'
import React from 'react'
import TopProducts from '@/components/TopProducts/TopProducts'
import Ripple from '@/components/ui/ripple'
import FeatureSection from '@/components/FeatureSection.jsx/FeatureSection'
import NewTopProducts from '@/components/TopProducts/NewTopProducts'
import TestimonialCarousel from '@/components/Testimonials/NewTestimonials'
import ResumeMakerBanner from '@/components/Banner/ResumeMakerBanner'
import CompanyLogoCarousel from '@/components/Caraousel/Caraousel'
import WhatsAppButton from '@/components/Whatsapp/WhatsAppButton'

const HomePage = () => {
  return (
    <div className='min-h-screen'>
        <ResumeMakerBanner/>
 
        <Hero />

        <div className='relative w-full -mt-[6rem] overflow-x-hidden overflow-y-hidden'>

        {/* <Ripple className = "top-20 md:block hidden " mainCircleSize={600}
        mainCircleOpacity={0.3}
        numCircles= {10}/>

        <Ripple className = " md:hidden block " mainCircleSize={100}
        mainCircleOpacity={0.8}
        numCircles= {10}/> */}
        
    


       
       
        <NumberCounter/>
        <CompanyLogoCarousel/>
        <NewTopProducts/>
        
        </div>
        <MarqueeDemo/>
        {/* <TestimonialCarousel/> */}
        <FeatureSection/>

        <WhatsAppButton phoneNumber="9560699151" />
        
        
        
        
     
    </div>
  )
}

export default HomePage