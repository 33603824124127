

import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import { useEffect, useRef,  useState } from "react"
import { motion } from "framer-motion"

import cars24logo from '../../../public/logos/cars24-seeklogo.png'
import zeptologo from '../../../public/logos/zepto-seeklogo.png'
import kpmglogo from '../../../public/logos/kpmg-seeklogo.png'
import deloittelogo from '../../../public/logos/Deloitte_idUZmqOXQ3_1.png'
import tata1mglogo from '../../../public/logos/tata1mg.png'
import zomatologo from '../../../public/logos/zomato-seeklogo.png'
import gtlogo from '../../../public/logos/Grant Thornton_idEvbVQe3L_1.png'
import eylogo from '../../../public/logos/EY_idKDHDpz8E_1.png'
import blinkitlogo from '../../../public/logos/Blinkit_idCmcpCDCZ_1.png'
import uclogo from '../../../public/logos/idt2m5clXX_logos.png'
import unstoplogo from '../../../public/logos/Unstop_idGARQA_PG_0.png'
import glglogo from '../../../public/logos/GLG_idZfDW2w6l_1.png'
import arbologo from '../../../public/logos/arbo.jpg'
import swiggylogo from '../../../public/logos/Swiggy_id8bItcgXR_1.png'
import nitiayoglogo from '../../../public/logos/niti-aayog-logo-vector-768x427.png'
import investindialogo from '../../../public/logos/invesindialogo.jpg'
import ficcilogo from '../../../public/logos/ficilogo.jpg'

const companies = [
  { name: "DELOITTE", logo:`${deloittelogo}` },
  { name: "KPMG", logo: `${kpmglogo}` },
  { name: "EY", logo: `${eylogo}`},
  { name: "GRANT THORNTON", logo: `${gtlogo}` },
  { name: "ZOMATO", logo: `${zomatologo}` },
  { name: "SWIGGY", logo: `${swiggylogo}` },
  { name: "BLINKIT", logo: `${blinkitlogo}` },
  { name: "CARS24", logo: `${cars24logo}` },
  { name: "URBAN COMPANY", logo: `${uclogo}` },
  { name: "NITI AAYOG", logo: `${nitiayoglogo}` },
  { name: "INVEST INDIA", logo: `${investindialogo}` },
  { name: "FICCI", logo: `${ficcilogo}` },
  { name: "UNSTOP", logo: `${unstoplogo}` },
  { name: "TATA 1MG", logo: `${tata1mglogo}` },
  { name: "ARBOLUS", logo: `${arbologo}` },
  { name: "GLG", logo: `${glglogo}` },
  { name: "ZEPTO", logo: `${zeptologo}` },
]


const companyList = [...companies, ...companies, ...companies, ...companies];

export default function CompanyLogoCarousel() {
  const carouselRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    if (carouselRef.current) {
      setScrollWidth(carouselRef.current.scrollWidth / 2);
    }
  }, []);

  return (
    <div className="w-full py-12 bg-black overflow-hidden">
      <div className="max-w-screen-xl mx-auto px-6">
        <h2 className="text-2xl md:text-3xl font-bold text-white mb-8 text-center">
          Students Placed At
        </h2>
        <div className="relative w-full overflow-hidden">
          {/* The key trick here is wrapping everything in a wide flex container */}
          <motion.div
            ref={carouselRef}
            className="flex w-max space-x-12 px-2"
            animate={{
              translateX: ["0%", "-50%"], // Moves half the duplicated list
            }}
            transition={{
              translateX: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 96, // Adjust speed (lower = faster)
                ease: "linear",
              },
            }}
          >
            {companyList.map((company, index) => (
              <div key={index} className="flex-none w-40">
                <div className="h-24 flex items-center justify-center">
                  <div className="relative w-32 h-12">
                    <img
                      src={company.logo || "/placeholder.svg"}
                      alt={`${company.name} logo`}
                      className="object-contain w-full h-full brightness-100"
                    />
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
}