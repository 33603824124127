import { motion } from "framer-motion";
import {
  Calendar,
  Clock,
  IndianRupee,
  Lock,
  Minus,
  Plus,
  CheckCircle,
} from "lucide-react";
import { useState, useContext, useEffect } from "react";
import { MainContext } from '@/context/MainContext'; // Assuming this is the correct import
import Checkout from '../PaymentGateway/Checkout'; // Adjust import path as needed
import { Navigate, useNavigate } from "react-router-dom";

export const NewCourseCard = ({
  course, // New prop to pass course details
  
  isExpired = false,
 
}) => {
  const { user } = useContext(MainContext);
  const [duration, setDuration] = useState(1);
  const [expired, setExpired] = useState(isExpired);
  const [price, setPrice] = useState(course?.extension_price);
  const navigate = useNavigate();

  // Payment info state
  const [info, setInfo] = useState({
    id: course?.course_id || '',
    name: user?.username,
    email: user?.email,
    phone_number: user?.phone_number,
    type: 'course',
    is_course_extension: true,
    extended_days: duration,
  });

  // Update info when duration changes
  useEffect(() => {
    setInfo((prevInfo) => ({ 
      ...prevInfo, 
      extended_days: duration 
    }));
  }, [duration]);

  // Check expiry status
  useEffect(() => {
    if (course?.expiry_date) {
      setExpired(new Date() > new Date(course.expiry_date));
    }
  }, [course?.expiry_date]);

  const pricePerDay = course.extension_price;
  const totalPrice = duration * pricePerDay;

  const handleDecreaseDuration = () => {
    if (duration > 1) setDuration(duration - 1);
  };

  const handleIncreaseDuration = () => {
    if (duration < 10) setDuration(duration + 1);
  };

  // Date formatting function
  const formatDate = (date) => {
    const validDate = new Date(date);
    return isNaN(validDate.getTime())
      ? 'Invalid date'
      : validDate.toLocaleString('en-IN', {
          timeZone: 'Asia/Kolkata',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative group max-w-xl mx-auto"
    >
      {/* Gradient border */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-50 transition duration-300" />

      <div className="relative p-3 sm:p-4 rounded-lg border backdrop-blur-sm transition-colors duration-300 bg-black/90 border-indigo-500/30 group-hover:border-indigo-400">
        {/* Header */}
        <div className="flex justify-between items-start gap-3 mb-4">
          <h3 className="text-base font-semibold text-white group-hover:text-indigo-400 transition-colors duration-300">
          {course.course_title}
          </h3>
          {expired ? (
            <span className="px-2 py-0.5 text-xs font-medium text-red-500 bg-red-500/10 rounded-full border border-red-500/20 flex items-center gap-1 whitespace-nowrap">
              <Lock className="w-3 h-3" />
              Expired
            </span>
          ) : (
            <span className="px-2 py-0.5 text-xs font-medium text-emerald-500 bg-emerald-500/10 rounded-full border border-emerald-500/20 flex items-center gap-1 whitespace-nowrap">
              <CheckCircle className="w-3 h-3" />
              Active
            </span>
          )}
        </div>

        {/* Duration Controls - Only show if expired */}
        {expired && (
          <div className="mb-4">
            <label className="text-white text-xs mb-1.5 block">
              Select Duration
            </label>
            <div className="flex items-center gap-3">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDecreaseDuration}
                disabled={duration <= 1}
                className={`p-1.5 rounded-md ${
                  duration <= 1
                    ? "bg-gray-800 text-gray-600 cursor-not-allowed"
                    : "bg-indigo-500/20 text-indigo-400 hover:bg-indigo-500/30"
                } transition-colors duration-300`}
              >
                <Minus className="w-4 h-4" />
              </motion.button>

              <div className="flex items-center gap-1.5 px-3 py-1.5 rounded-md bg-indigo-500/10">
                {/* <Clock className="w-4 h-4 text-indigo-400" /> */}
                <span className="text-sm font-medium text-white min-w-[60px] text-center">
                  {duration} {duration === 1 ? "Day" : "Days"}
                </span>
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleIncreaseDuration}
                disabled={duration >= 10}
                className={`p-1.5 rounded-md ${
                  duration >= 10
                    ? "bg-gray-800 text-gray-600 cursor-not-allowed"
                    : "bg-indigo-500/20 text-indigo-400 hover:bg-indigo-500/30"
                } transition-colors duration-300`}
              >
                <Plus className="w-4 h-4" />
              </motion.button>
            </div>
          </div>
        )}

        {/* Price Section */}
        <div className="mb-4 p-3 rounded-md bg-indigo-500/5 border border-indigo-500/10">
          {expired ? (
            <>
              
             
              
              <div className=" bg-indigo-500/20 " />
              <div className="flex justify-between items-center">
                <span className="text-white text-xs">Total Price</span>
                <div className="flex items-center gap-0.5 text-indigo-400 font-medium">
                  <IndianRupee className="w-3 h-3 text-white" />
                  <span className="text-white">{totalPrice}</span>
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-between items-center">
              <button className="rounded-md bg-emerald-500/20 text-emerald-500 border border-emerald-500/20 px-3 py-2" onClick={() => navigate(`/view-courses/${course.course_id}`)}>View Course</button>
            </div>
          )}
        </div>

        {/* Action Button */}
        {expired && (
          <Checkout 
            info={{
              ...info,
              extended_days: duration
            }} 
            title = {"Extend"}
          />
        )}

        {/* Timeline */}
        <div className="space-y-1.5 mt-4">
          <div className="flex items-center text-gray-400 gap-1.5">
            <div className="p-1.5 rounded-md bg-indigo-500/10">
              <Calendar className="w-3 h-3 text-indigo-400" />
            </div>
            <span className=" text-white">Purchased: {formatDate(course.purchased_date)}</span>
          </div>
          <div className="flex items-center text-gray-400 gap-1.5">
            <div className="p-1.5 rounded-md bg-indigo-500/10">
              <Calendar className="w-3 h-3 text-indigo-400" />
            </div>
            <span className=" text-white">Expires: {formatDate(course.expiry_date)}</span>
          </div>
        </div>

        {/* Status Notice */}
        {expired ? (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 p-3 rounded-md bg-red-500/10 border border-red-500/20"
          >
            <p className="text-red-500 text-xs flex items-center gap-1.5">
              <Lock className="w-3 h-3" />
              This item has expired. Please extend if you wish to continue using it.
            </p>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 p-3 rounded-md bg-emerald-500/10 border border-emerald-500/20"
          >
            <p className="text-emerald-500 text-xs flex items-center gap-1.5">
              <CheckCircle className="max-w-3 min-w-3 max-h-3" />
              Learning on your phone is cool, but it’s like watching a Netflix blockbuster on a tiny screen—good, but not great. Switch to a laptop for the full IMAX experience!
            </p>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default NewCourseCard;