// export const url = "https://your-base-url.com"; // Replace with actual base URL

export const sessionData = [
    {
      id: 101,
      name: 'Charchit Kurra',
      image: 'charchit_author.jpg',
      icon: 'clock',
      description: "I'm Charchit Kurra, an active LinkedIn content creator with a passion for helping students and professionals secure valuable internships and placements. Over the past few years, I have gained hands-on experience through three prominent internships at BCG, Blinkit, Tata 1mg, and Grant Thornton. These experiences have equipped me with the knowledge and strategies to navigate the competitive world of off-campus internships and placements successfully.",
      subfunction: '60 mins',
      function: 'Video Meeting',
      price: 50,
      discounted_price: 40,
      type: 'one-one-call',
      rating: 4.8,
      sales: 100,
      link:"/bookcall/101",

      tags: [
        {
          name: 'Best For Placements',
          color: 'bg-yellow-400',
        },
    
      ],
      
    },

    {
        id: 102,
        name: 'Chinmay Das',
        image: 'chinmay_author.jpg',
        icon: 'clock',
        description: "I’m Chinmay Das, a Master's student in Economics and a LinkedIn content creator with a passion for helping students secure valuable internships. Over the past few years, I have gained hands-on experience through four prominent internships at Zomato, Grant Thornton, Urban Company, and NITI Aayog. These experiences have provided me with deep insights into navigating the competitive landscape of off-campus internships.",
        subfunction: '60 mins',
        function: 'Video Meeting',
        price: 50,
        discounted_price: 40,
        type: 'one-one-call',
        rating: 4.8,
        sales: 100,
        link:"/bookcall/201",

        tags: [
          {
            name: 'Best For Internships',
            color: 'bg-yellow-400',
          },
      
        ],
        
      }



    // Add more session data as needed
  ];
  