import ModulesSection from '@/components/ModulesSection/ModulesSection'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer'
import VideoSection from '@/components/VideoSection/VideoSection'
import React, { useContext, useEffect, useState } from 'react'
import { data } from './data'
import Navbar from '@/components/Navbar/Navbar'
import { baseURL } from '@/utility/api'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { MainContext } from '@/context/MainContext'

const CoursePage = () => {
  const [module, setModule] = React.useState(null);
  const [data,setData] = React.useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const handleModuleChange = (module) => {
    setModule(module);
  };
  const {id} = useParams();

  useEffect(() => {
    // Fetch data from the backend
    async function fetchData() {
      // Your code here
      setIsLoading(true)
      const response = await axios.get(`${baseURL}/courses/view-course/${id}`,{ withCredentials: true });
      setData(response.data);
      setModule(response.data[0]);
      setIsLoading(false);
      
    }
    fetchData();
    
  }
  , [])

    
  return (
    <>
    
    <div className='md:flex mt-20'>
      {isLoading ? <div>Loading...</div> : 
      <>
        <ModulesSection data={data} handleModuleChange={handleModuleChange} module={module}/>
        <VideoSection data={data} module={module}/>
        </>}
      
      
    </div>
    </>
  )
}

export default CoursePage