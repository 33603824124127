  import React, { useState, useEffect, useContext } from "react";
import {
  ArrowRight,
  PlayCircle,
  CheckCircle2,
  ChevronDown,
  Star,
  Users,
  Clock,
  Download,
} from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MainContext } from "@/context/MainContext";
import { baseURL } from "@/utility/api";
import axios from "axios";

const GradientButton = ({
  children,
  onClick,
  className,
  variant = "primary",
}) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 rounded-full font-medium text-sm relative group overflow-hidden ${
      variant === "primary"
        ? "bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-700 hover:to-indigo-700 text-white"
        : "bg-white/10 hover:bg-white/20 text-white"
    } ${className}`}
  >
    <span className="relative z-10 flex items-center gap-2">{children}</span>
    <div className="absolute inset-0 -z-10 bg-gradient-to-r from-violet-600/40 to-indigo-600/40 opacity-0 group-hover:opacity-100 transition-opacity blur-xl"></div>
  </button>
);

const Card = ({ children, className }) => (
  <div
    className={`bg-gray-900/50 backdrop-blur-xl border border-gray-800/60 rounded-xl hover:border-violet-500/30 transition-all duration-300 ${className}`}
  >
    {children}
  </div>
);

const CourseDescV2 = () => {
  const [activeModule, setActiveModule] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [course,setCourse] = useState(null);
  const {id} = useParams();
  const {user} = useContext(MainContext);
  const [hasPurchased,setHasPurchased] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading,setIsLoading] = useState(true);
  const [isFirstTime,setIsFirstTime] = useState(true);
  useEffect(() => {
    setIsVisible(true);
    async function fetchData(){
      setIsLoading(true);
      const response = await axios.get(`${baseURL}/courses/get-course/${id}`, { withCredentials: true });
      setCourse(response.data);
      if(user){
        const response2 = await axios.get(`${baseURL}/courses/has-purchased/${id}`, { withCredentials: true });
        setHasPurchased(response2.data.hasPurchased);
        setIsFirstTime(response2.data.isFirstTime);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [user]);

  const handleNavigation = () => {
    if(isFirstTime){
      const currentPath = location.pathname;  // Get the current URL path
      navigate(`${currentPath}/pay`); 
    }else{
      navigate(`/my-courses`);
    }
     // Append `/pay` to the current URL
  }

  const features = [
    { icon: Clock, text: "10 days validity" },
    { icon: Download, text: "Downloadable Resources" },
    // { icon: Users, text: "Community Support" },
  ];

  const stats = [
    { value: "600+", label: "Students Enrolled" },
    { value: "4.8", label: "Average Rating" },
    { value: "1.5+", label: "Hours of Content" },
  ];

  const modules = [
    {
      id: 1,
      title: "The Kickstart",
      description: "Meet your mentor and hear the story behind this course.",
      lessons: [
        "Course Introduction",
        "Setting Expectations",
        "Your Learning Path",
      ],
    },
    {
      id: 2,
      title: "Decoding Internship Roles",
      description:
        "Dive into the world of internships and explore roles across industries.",
      lessons: [
        "Understanding Different Roles",
        "Industry Deep Dive",
        "Role Matching",
      ],
    },
    {
      id: 3,
      title: "Brand Radar",
      description:
        "Learn how to identify the right brands for your dream roles.",
      lessons: ["Company Research", "Brand Alignment", "Target Selection"],
    },
    {
      id: 4,
      title: "The Art of Cold Reachouts",
      description:
        "Become a pro at crafting cold emails that demand attention.",
      lessons: ["Email Psychology", "Template Creation", "Follow-up Strategy"],
    },
    {
      id: 5,
      title: "The Resume Makeover",
      description: "Transform your resume into a spotlight-worthy document.",
      lessons: ["Resume Structure", "Content Strategy"],
    },
    {
      id: 6,
      title: "Cracking the Interview Code",
      description: "Master the essentials of interview preparation.",
      lessons: [
        "Interview Fundamentals",
        "Question Strategies",
        "Mock Interviews",
      ],
    },
    {
      id: 7,
      title: "FAQs",
      description: "Tried answering the most frequent questions that students often ask.",
      lessons: [
        
        
      ],
    }
  ];

  return (
    <div className="min-h-screen md:-mt-16 bg-gray-950 text-white">
      {/* Animated Background Pattern */}
      <div className="fixed inset-0 -z-10 bg-[linear-gradient(to_bottom,rgba(0,0,0,0)_0%,rgba(0,0,0,0.8)_100%)]">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSA2MCAwIEwgMCAwIDAgNjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSg5OSwgMTAyLCAyNDEsIDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20"></div>
      </div>

      {/* Hero Section */}
      <div className="pt-24 lg:pt-40 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-16 lg:gap-24 items-center">
            <div
              className={`space-y-8 transition-all duration-1000 ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <div className="inline-flex items-center gap-2 text-sm bg-gray-900/50 px-4 py-2 rounded-full border border-gray-800/50 backdrop-blur-sm">
                <span className="w-2 h-2 bg-green-400 rounded-full animate-pulse"></span>
                <span className="text-gray-300 font-medium">
                Use code OFF50 and get ₹50 off!
                </span>
              </div>

              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-black text-white leading-tight">
                Master the Art of{" "}
                <span className="relative">
                  <span className="bg-gradient-to-r from-violet-400 to-indigo-400 bg-clip-text text-transparent">
                    Landing Internships
                  </span>
                  {/* <svg
                    className="absolute -bottom-2 left-0 w-full"
                    viewBox="0 0 200 8"
                    fill="none"
                  >
                    <path
                      d="M0 4C50 4 50 1 100 1C150 1 150 7 200 7"
                      stroke="url(#paint0_linear)"
                      strokeWidth="2"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="0"
                        y1="4"
                        x2="200"
                        y2="4"
                      >
                        <stop stopColor="#8B5CF6" />
                        <stop offset="1" stopColor="#6366F1" />
                      </linearGradient>
                    </defs>
                  </svg> */}
                </span>
              </h1>

              <p className="text-gray-400 text-lg sm:text-xl leading-relaxed">
              Learn proven strategies to secure off-campus internships with ease. From crafting impactful cold emails to building a standout resume, this course gives you actionable tips to land opportunities that others miss. Affordable, practical, and designed for students who want to stand out.
              </p>

              <div className="flex flex-wrap items-center gap-6">
                <GradientButton className="group" onClick={hasPurchased ? () => {navigate(`/view-courses/${id}`)} : handleNavigation}>
                  {!hasPurchased ? <> {isFirstTime ? "Enroll now" : "Extend"}  <br></br></> : "View Course"}
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </GradientButton>

                {/* <button className="flex items-center gap-3 text-gray-300 hover:text-white transition-colors group">
                  <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-800/50 group-hover:bg-gray-800 transition-colors">
                    <PlayCircle className="w-6 h-6" />
                  </div>
                  <span className="font-medium">Watch Demo</span>
                </button> */}
              </div>

              <div className="grid grid-cols-3 gap-6">
                {stats.map((stat, index) => (
                  <Card key={index} className="p-4 text-center group">
                    <div className="text-2xl font-bold bg-gradient-to-r from-violet-400 to-indigo-400 bg-clip-text text-transparent group-hover:scale-110 transition-transform">
                      {stat.value}
                    </div>
                    <div className="text-sm text-gray-400">{stat.label}</div>
                  </Card>
                ))}
              </div>
            </div>

            <div
              className={`relative group transition-all duration-1000 delay-300 ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-violet-600/20 to-indigo-600/20 blur-3xl transition-all duration-500 group-hover:blur-4xl"></div>
              <Card className="relative overflow-hidden transform group-hover:scale-[1.02] transition-all duration-500">
                <div className="absolute top-2 right-2 flex items-center gap-1 bg-gray-950/80 backdrop-blur-sm px-3 py-1 rounded-full text-sm">
                  <Star className="w-4 h-4 text-yellow-400 fill-yellow-400" />
                  <span className="text-gray-200">4.8</span>
                </div>

                <img
                  src="/course101thumbnail.png"
                  alt="Course Preview"
                  className="w-full  object-cover"
                />

                <div className="p-6 space-y-6">
                  <div className="flex flex-wrap gap-4">
                    {features.map((feature, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 text-sm text-gray-300"
                      >
                        <feature.icon className="w-4 h-4 text-violet-400" />
                        <span>{feature.text}</span>
                      </div>
                    ))}
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-white">
                      What's Included:
                    </h3>
                    <div className="grid gap-3">
                      {course?.main_features.map((item, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-3 text-gray-300 group/item"
                        >
                          <div className="w-5 h-5 rounded-full bg-violet-400/10 flex items-center justify-center flex-shrink-0 group-hover/item:bg-violet-400/20 transition-colors">
                            <CheckCircle2 className="w-3 h-3 text-violet-400" />
                          </div>
                          <span className="group-hover/item:text-white transition-colors">
                            {item}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="pt-4 border-t border-gray-800">
                    <div className="flex items-center justify-between">
                      <div className="space-y-1">
                        <div className="text-gray-400 text-sm line-through">
                          ₹{course?.price}
                        </div>
                        <div className="text-2xl font-bold text-white">
                          ₹{course?.discounted_price}
                        </div>
                      </div>
                      <GradientButton onClick={hasPurchased ? () => {navigate(`/view-courses/${id}`)} : handleNavigation}>
                        {!hasPurchased ? <>{isFirstTime ? "Get Started" : "Extend"} </>: "View Course"}
                        <ArrowRight className="w-4 h-4" />
                      </GradientButton>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* Curriculum Section */}
      <div className="py-24 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-gray-900/50 to-gray-950"></div>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center space-y-4 mb-16">
            <h2 className="text-3xl sm:text-4xl font-bold text-white">
              Course Curriculum
            </h2>
            <p className="text-gray-400 text-lg">
              Master every aspect of landing your dream internship
            </p>
          </div>

          <div className="space-y-6">
            {modules.map((module) => (
              <Card key={module.id}>
                <button
                  onClick={() =>
                    setActiveModule(
                      activeModule === module.id ? null : module.id
                    )
                  }
                  className="w-full p-6 flex items-center justify-between text-left hover:bg-gray-800/20 transition-colors"
                >
                  <div className="space-y-2">
                    <h3 className="text-lg font-semibold text-white flex items-center gap-3">
                      <span className="w-8 h-8 rounded-full bg-violet-400/10 flex items-center justify-center text-violet-400 text-sm font-bold">
                        {module.id}
                      </span>
                      {module.title}
                    </h3>
                    <p className="text-gray-400">{module.description}</p>
                  </div>
                  <ChevronDown
                    className={`w-5 h-5 text-gray-400 transition-transform duration-300 ${
                      activeModule === module.id ? "rotate-180" : ""
                    }`}
                  />
                </button>

                <div
                  className={`transition-all duration-300 ease-in-out ${
                    activeModule === module.id
                      ? "max-h-96 opacity-100"
                      : "max-h-0 opacity-0"
                  } overflow-hidden`}
                >
                  <div className="p-6 pt-0 space-y-4">
                    <ul className="space-y-2">
                      {module.lessons.map((lesson, index) => (
                        <li key={index} className="flex items-center gap-3 text-gray-300">
                          <CheckCircle2 className="w-4 h-4 text-violet-400" />
                          {lesson}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="py-4 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-gray-900/50 to-gray-950"></div>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center space-y-4 mb-16">
            <h2 className="text-3xl sm:text-4xl font-bold text-white">
              Resources Included
            </h2>
           
          </div>

          <div className="space-y-6">
            {course?.resources.map((module,index) => (
              <Card key={index}>
                <div
  
                  className="w-full p-6 flex items-center justify-between text-left hover:bg-gray-800/20 transition-colors"
                >
                  <div className="space-y-2">
                    <h3 className="text-lg font-semibold text-white flex items-center gap-3">
                      <span className="w-8 h-8 rounded-full bg-violet-400/10 flex items-center justify-center text-violet-400 text-sm font-bold">
                        {index+1}
                      </span>
                      {module}
                    </h3>
                    {/* <p className="text-gray-400">{module.description}</p> */}
                  </div>
                 
                </div>

                {/* <div
                  className={`transition-all duration-300 ease-in-out ${
                    activeModule === module.id
                      ? "max-h-96 opacity-100"
                      : "max-h-0 opacity-0"
                  } overflow-hidden`}
                >
                  <div className="p-6 pt-0 space-y-4">
                    <ul className="space-y-2">
                      {module.lessons.map((lesson, index) => (
                        <li key={index} className="flex items-center gap-3 text-gray-300">
                          <CheckCircle2 className="w-4 h-4 text-violet-400" />
                          {lesson}
                        </li>
                      ))}
                    </ul>
                  </div> */}
               
              </Card>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default CourseDescV2;