import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import { products } from '@/components/ProductsSection/data'
import SessionComponent from '@/components/Sessions/SessionComponent'
import {sessionData} from '@/components/Sessions/SessionData';
import axios from 'axios'
import { baseURL } from '@/utility/api'

const SessionPage = () => {
    const [data, setData] = useState([]);
      // const handleTabs = (type) => {
      //   if(type === 'all') return setData(products);
      //   const newData = products.filter((product) => product.type === type) {*OFF NO USE*}
      //   setData(newData);
      // }
  
     const [isVisible, setIsVisible] = useState(false);

     console.log(data);
     console.log('sess', sessionData);


     useEffect(() => {
      setIsVisible(true);
      // async function fetchData() {
      //   // const response = await axios.get(`${baseURL}/resume/get-resume-services`);
      //   setData(SessionData);
      // }
      // fetchData();
      if (sessionData && Array.isArray(sessionData)) {
        setData(sessionData);
      } else {
        console.error("sessionData is not available or not an array");
      }
     
      
    }, []) 
  
  return (


    <div className='min-h-screen  bg-gray-950 text-white pt-24 lg:pt-40 pb-24 '>
      <div className="fixed inset-0 -z-10 bg-[linear-gradient(to_bottom,rgba(0,0,0,0)_0%,rgba(0,0,0,0.8)_100%)]">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSA2MCAwIEwgMCAwIDAgNjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSg5OSwgMTAyLCAyNDEsIDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20"></div>
      </div>

      <motion.div className=" w-full"  initial={{opacity:0}}
          whileInView={{opacity:1}}
          transition={{duration:0.5,delay:0.5}}>
            <div
              className={`space-y-8 transition-all duration-1000 ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
            <h1 className="text-5xl text-center sm:text-5xl lg:text-6xl font-black text-white leading-tight">
              Sessions {" "}
                <span className="relative">
                  {/* <span className="bg-gradient-to-r from-violet-400 to-indigo-400 bg-clip-text text-transparent">
                 ions
                  </span> */}
                </span>
              </h1>
              </div>
            </motion.div>

            <SessionComponent sessions={data}  />
            
       

    </div>
  )
}

export default SessionPage;
