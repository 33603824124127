import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Star, MessageSquare, ShoppingCartIcon, X, AlertCircle } from "lucide-react";
import { baseURL } from "@/utility/api";
import axios from "axios";

// Alert Component
const ValidationAlert = ({ message }) => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    className="flex items-center gap-2 text-red-400 text-sm mt-2"
  >
    <AlertCircle className="w-4 h-4" />
    {message}
  </motion.div>
);

const ConfirmationDialog = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="relative w-full max-w-md p-6 rounded-lg bg-gray-900 border border-indigo-500/30"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X className="w-5 h-5" />
        </button>
        
        <div className="text-center">
          <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-500/20 flex items-center justify-center">
            <MessageSquare className="w-8 h-8 text-indigo-400" />
          </div>
          
          <h3 className="text-xl font-semibold text-white mb-2">
            Thank you for your feedback!
          </h3>
          
          <p className="text-gray-300 mb-6">
            Your testimonial has been successfully submitted. We appreciate your time and valuable feedback.
          </p>
          
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onClose}
            className="w-full py-3 px-6 rounded-lg bg-indigo-500 hover:bg-indigo-600 text-white transition-colors duration-300"
          >
            Close
          </motion.button>
        </div>
      </motion.div>
    </motion.div>
  );
};

const TestimonialPage = () => {
  const [rating, setRating] = useState(0);
  const [hoveredStar, setHoveredStar] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    product_id: "",
    type: "",
    testimonial: "",
    name: "",
    label: "",
  });

  // Validation states
  const [touched, setTouched] = useState({
    product: false,
    rating: false,
    testimonial: false
  });
  const [errors, setErrors] = useState({});

  const products = [
    {label : "Master the Art of Landing Internships",id:101,type:'courses'},
    {label : "One-to-One Mentorship by Charchit Kurra",id:101,type:'sessions'},
    {label : "Resume Writing Service",id:102,type:'sessions'},
    {label : "Resume Template",id:101,type:'products'},
    {label : "Resume Review",id:101,type:'services'},
    {label : "Mailing Template",id:102,type:'products'},
  ];

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.product_id) {
      newErrors.product = "Please select a product";
    }
    
    if (rating === 0) {
      newErrors.rating = "Please select a rating";
    }
    
    if (!formData.testimonial.trim()) {
      newErrors.testimonial = "Please enter your testimonial";
    }
    // } else if (formData.testimonial.trim().length < 10) {
    //   newErrors.testimonial = "Testimonial must be at least 10 characters long";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFormData({
      product_id: "",
      type: "",
      testimonial: "",
      name: "",
      label: "",
    });
    setRating(0);
    setTouched({
      product: false,
      rating: false,
      testimonial: false
    });
    setErrors({});
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    // Mark all fields as touched
    setTouched({
      product: true,
      rating: true,
      testimonial: true
    });

    if (!validateForm()) {
      // Scroll to the first error if any
      const firstError = document.querySelector('.text-red-400');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    try {
      const response = await axios.post(`${baseURL}/testimonial/save-testimonial`, { ...formData, rating });
      console.log(response.data);
      setShowConfirmation(true);
      resetForm();
    } catch (error) {
      console.error('Error submitting testimonial:', error);
      setErrors(prev => ({
        ...prev,
        submit: "Failed to submit testimonial. Please try again."
      }));
    }
  };

  const handleBlur = (field) => {
    setTouched(prev => ({ ...prev, [field]: true }));
    validateForm();
  };

  return (
    <section className="py-20 relative bg-black">
      <div className="absolute inset-0 bg-gradient-to-b from-indigo-900/10 via-black to-black" />
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,rgba(99,102,241,0.1),transparent_50%)]" />

      <div className="max-w-3xl mx-auto px-4 relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-bold text-center text-white mb-16"
        >
          Share Your Experience
          <div className="w-24 h-1 bg-gradient-to-r from-white to-gray-500 mx-auto mt-4 rounded-full" />
        </motion.h2>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="relative group"
        >
          <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-50 transition duration-300" />

          <form
            onSubmit={handleSubmit}
            className="relative p-6 rounded-lg border backdrop-blur-sm bg-black/80 border-indigo-500/30"
          >
            <div className="space-y-6">
              {/* Custom Dropdown */}
              <div>
                <label className="block mb-2 font-medium text-gray-200">
                  Select Product <span className="text-red-400">*</span>
                </label>
                <div className="relative">
                  <motion.button
                    type="button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    onBlur={() => handleBlur('product')}
                    className={`w-full p-3 rounded-lg border bg-gray-900 text-gray-200 flex items-center justify-between ${
                      touched.product && errors.product ? 'border-red-400' : 'border-gray-700'
                    }`}
                  >
                    <span className="flex items-center gap-2">
                      <ShoppingCartIcon className="w-5 h-5 text-indigo-400" />
                      {formData.label || "Choose a product..."}
                    </span>
                    <motion.svg
                      animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                      className="w-5 h-5 text-indigo-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M6 8l4 4 4-4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </motion.svg>
                  </motion.button>

                  <AnimatePresence>
                    {touched.product && errors.product && (
                      <ValidationAlert message={errors.product} />
                    )}
                  </AnimatePresence>

                  {isDropdownOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute z-20 w-full mt-2 bg-gray-900 border border-gray-700 rounded-lg shadow-xl"
                    >
                      {products.map((product) => (
                        <motion.button
                          key={product.label}
                          type="button"
                          whileHover={{
                            backgroundColor: "rgba(99,102,241,0.1)",
                          }}
                          onClick={() => {
                            setFormData({ ...formData, product_id: product.id, type: product.type, label: product.label });
                            setIsDropdownOpen(false);
                            setErrors(prev => ({ ...prev, product: undefined }));
                          }}
                          className="w-full flex items-center gap-2 px-3 py-2 text-left text-gray-200 hover:text-white"
                        >
                          <ShoppingCartIcon className="w-4 h-4 text-indigo-400" />
                          {product.label}
                        </motion.button>
                      ))}
                    </motion.div>
                  )}
                </div>
              </div>

              {/* Rating */}
              <div>
                <label className="block mb-2 font-medium text-gray-200">
                  Rating <span className="text-red-400">*</span>
                </label>
                <div className="flex gap-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <motion.button
                      key={star}
                      type="button"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => {
                        setRating(star);
                        setErrors(prev => ({ ...prev, rating: undefined }));
                        setTouched(prev => ({ ...prev, rating: true }));
                      }}
                      onMouseEnter={() => setHoveredStar(star)}
                      onMouseLeave={() => setHoveredStar(0)}
                      className="p-1 rounded-lg transition-colors hover:bg-gray-800"
                    >
                      <Star
                        className={`w-8 h-8 ${
                          star <= (hoveredStar || rating)
                            ? 'fill-yellow-400 text-yellow-400'
                            : touched.rating && errors.rating
                            ? 'text-red-400'
                            : 'text-gray-600'
                        }`}
                      />
                    </motion.button>
                  ))}
                </div>
                <AnimatePresence>
                  {touched.rating && errors.rating && (
                    <ValidationAlert message={errors.rating} />
                  )}
                </AnimatePresence>
              </div>

              {/* Testimonial */}
              <div>
                <label className="block mb-2 font-medium text-gray-200">
                  Your Testimonial <span className="text-red-400">*</span>
                </label>
                <textarea
                  value={formData.testimonial}
                  onChange={(e) => {
                    setFormData({ ...formData, testimonial: e.target.value });
                    if (e.target.value.trim().length >= 10) {
                      setErrors(prev => ({ ...prev, testimonial: undefined }));
                    }
                  }}
                  onBlur={() => handleBlur('testimonial')}
                  className={`w-full p-3 rounded-lg border bg-gray-900 text-gray-200 focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
                    touched.testimonial && errors.testimonial ? 'border-red-400' : 'border-gray-700'
                  }`}
                  rows={4}
                  placeholder="Share your experience..."
                />
                <AnimatePresence>
                  {touched.testimonial && errors.testimonial && (
                    <ValidationAlert message={errors.testimonial} />
                  )}
                </AnimatePresence>
              </div>

              {/* Name */}
              <div>
                <label className="block mb-2 font-medium text-gray-200">
                  Name (Optional)
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  className="w-full p-3 rounded-lg border bg-gray-900 border-gray-700 text-gray-200 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                  placeholder="Enter your name..."
                />
              </div>

              {/* Submit Button */}
              <motion.button
                type="submit"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full py-3 px-6 rounded-lg flex items-center justify-center gap-2 bg-indigo-500 hover:bg-indigo-600 text-white transition-colors duration-300"
              >
                <MessageSquare className="w-5 h-5" />
                Submit Testimonial
              </motion.button>

              {/* Submit Error */}
              <AnimatePresence>
                {errors.submit && (
                  <ValidationAlert message={errors.submit} />
                )}
              </AnimatePresence>
            </div>
          </form>
        </motion.div>

        <AnimatePresence>
          <ConfirmationDialog 
            isOpen={showConfirmation} 
            onClose={() => setShowConfirmation(false)} 
          />
        </AnimatePresence>
      </div>
    </section>
  );
};

export default TestimonialPage;