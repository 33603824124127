import CallSection from '@/components/CallSection/CallSection'
import CallSectionV2 from '@/components/CallSection/CallSectionV2';
import { baseURL } from '@/utility/api'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const CallPage = () => {
  const [session,setSession] = useState(null);
  const {id} = useParams();

  useEffect(() => {
    async function fetchData() {
      const response = await axios.post(`${baseURL}/session/get-details`,{id:id} )
      setSession(response.data);
    }
    fetchData()
  }
  ,[])

  return (
    <div className=''>
     {/* <CallSection session={session}/> */}
     <CallSectionV2 session={session} id={id}/>
    </div>
  )
}

export default CallPage