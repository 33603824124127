import { url } from '@/utility/api';
import { ArrowRight, Star } from 'lucide-react'
import React from 'react'

const NewCards = ({product,key}) => {

  const handleNavigation = (link) => {
        const currentPath = location.pathname; 
        
          window.location.href =`${url}${link}`
        
      } 
  return (
        <div
            key={key}
            className="group p-6 bg-gray-800 rounded-2xl shadow-lg border border-gray-700 max-w-lg hover:shadow-violet-500 transition-all duration-300 flex  flex-col justify-between"
          >
            <div>
            <div className="flex justify-between items-start mb-4 gap-4">
              <div className=''>
                <h3 className="text-2xl font-bold text-white mb-2">
                  {product?.title}
                </h3>
                <div className="flex items-center gap-2">
                  <Star className="w-5 h-5 text-yellow-500" />
                  <span className="text-yellow-500">{product?.rating}</span>
                </div>
              </div>
              <span className="text-3xl font-bold text-violet-400">
              ₹{product?.discounted_price}
              </span>
            </div>
            <p className="text-gray-300 mb-4">
              <span className="text-violet-400 font-semibold">
              {product.subfunction}
              </span>
              <br />
              {product?.description}
            </p>
            </div>
            <button onClick={() => handleNavigation(product?.link)} className="w-full py-3 bg-violet-600 hover:bg-violet-700 text-white rounded-lg transition-colors duration-300 flex items-center justify-center gap-2">
              Purchase Now
              <ArrowRight className="w-4 h-4" />
            </button>
          </div>
  )
}

export default NewCards