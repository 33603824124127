import { url } from '@/utility/api';
import { ArrowRight, Star } from 'lucide-react';
import React from 'react';

const SessionDisplay = ({ session }) => {
  const handleNavigation = (link) => {
    window.location.href = `${url}${link}`;
  };

  return (
//     <div className="group p-6 bg-gray-800 rounded-2xl shadow-lg border border-gray-700 max-w-lg hover:shadow-violet-500 transition-all duration-300 flex flex-col justify-between">
//     <div>
//       <div className="flex justify-between items-start mb-4 gap-4">
//         <div className="">
//           <h3 className="text-2xl font-bold text-white mb-2">
//             {session.name}
//           </h3>
//           <div className="flex items-center gap-2">
//             <Star className="w-5 h-5 text-yellow-500" />
//             <span className="text-yellow-500">{session.rating}</span>
//           </div>
//         </div>
//       </div>
//       <p className="text-gray-300 mb-4">
//         <span className="text-violet-400 font-semibold">
//           {session.subfunction}
//         </span>
//         <br />
//         {session.description}
//       </p>
//     </div>
//     <button
//       onClick={() => handleNavigation(session.link)}
//       className="w-full py-3 bg-violet-600 hover:bg-violet-700 text-white rounded-lg transition-colors duration-300 flex items-center justify-center gap-2"
//     >
//       Learn More
//       <ArrowRight className="w-4 h-4" />
//     </button>
//   </div>

<div className="group p-4 bg-gray-800 rounded-2xl shadow-lg border border-gray-700 max-w-md hover:shadow-violet-500 transition-all duration-300 flex flex-col h-full mx-auto">
      
{/* Circular Image */}
<img 
  src={session.image} 
  alt={session.name} 
  className="w-32 h-32 object-cover rounded-full mx-auto mb-4"
/>

{/* Name */}
<h3 className="text-2xl font-bold text-white text-center mb-4">{session.name}</h3>

<div className='flex justify-center w-full mx-auto space-x-2 mb-4'>
              {session.tags.map((tag) => (
                <span key={tag.name} className={`text-xs font-semibold bg-blue-200  text-blue-800 px-2 py-1 rounded-[1rem]`}>{tag.name}</span>
              ))}
            </div>

{/* Description */}
<p className="text-gray-300 text-base text-center flex-grow">{session.description}</p>

{/* Button wrapped in div to align it at bottom */}
<div className="mt-2">
  <button
    onClick={() => handleNavigation(session.link)}
    className="w-full py-3 bg-violet-600 hover:bg-violet-700 text-white rounded-2xl transition-all duration-300 flex items-center justify-center gap-2 text-base font-semibold"
  >
    Book Call <ArrowRight className="w-4 h-4" />
  </button>
</div>

</div>
  );
};

export default SessionDisplay;