import { MagicCard } from "../ui/magic-card"
import Card from "./Card"
import DarkComingSoonCard from "./ComingSoon"

const courses = [
  {
    image: "./course101thumbnail.png",
    title: "Mastering Off-Campus Internships",
    rating: 4.8,
    reviews: 219,
    duration: "1h 30m",
    tags:[
      {
        name : 'Best Seller',
        color : 'bg-yellow-400'
      },
      {
        name : 'Popular',
        color : 'bg-green-400'
      }
    ],
    lessons: 7,
    instructor: {
      name: "Charchit Kurra",
      avatar: "charchit_author.jpg",
    },
    price: 199.00,
    link : "/courses/101",
    people_bought : "598",
  },
  {
    image: "./resume102thumbnail.jpg",
    title: "Resume Writing Service",
    rating: 4.7,
    reviews: 1341,
    tags:[
      {
        name : 'High Demand',
        color : 'bg-yellow-400'
      },
      {
        name : 'Fast Selling',
        color : 'bg-green-400'
      }
    ],
    duration: "2 Days",
    lessons: 0,
    instructor: {
      name: "Charchit Kurra",
      avatar: "charchit_author.jpg",
    },
    people_bought : "15",
    price: 499.00,
    link : "/resume-maker",

  },
  // {
  //   image: "https://i0.wp.com/www.bishoprook.com/wp-content/uploads/2021/05/placeholder-image-gray-16x9-1.png?ssl=1",
  //   title: "The Web Developer Bootcamp: A To Z Course 2022",
  //   rating: 4.7,
  //   reviews: 5341,
  //   duration: "3h 15m",
  //   tags:[
  //     {
  //       name : 'Best Seller',
  //       color : 'bg-yellow-400'
  //     },
  //     {
  //       name : 'Popular',
  //       color : 'bg-green-400'
  //     }
  //   ],
  //   lessons: 22,
  //   instructor: {
  //     name: "Charchit Kurra",
  //     avatar: "https://topmate.io/_next/image?url=https%3A%2F%2Ftopmate-staging.s3.ap-south-1.amazonaws.com%2FaqTDcJ9EXdWAhBkYsUeKRw.jpg&w=256&q=75&dpl=dpl_zXp3QtRWYXMagD7RGyFd2ATmt2RS",
  //   },
  //   price: 32.00,
  // },

]

export default function NewTopProducts() {
  return (
    
    <div className="md:my-20 my-10 container flex justify-center">
  <div className="max-w-6xl mx-auto ">
    <h1 className="text-[3rem] font-style-gradient mb-5 text-center">Top Products</h1>
    <div className="grid grid-cols-1 md:grid-cols-2 justify-center gap-20">
      {courses.map((course, index) => (
        <MagicCard
          className="cursor-pointer max-w-md mx-auto"
          gradientColor={"rgb(251, 144, 20)"}
          gradientOpacity={0.4}
          key={index}
        >
          <Card key={index} course={course} />
        </MagicCard>
      ))}
      <div className="h-full flex items-center justify-center w-full">
        {/* <DarkComingSoonCard /> */}
      </div>
    </div>
  </div>
</div>

    
  )
}