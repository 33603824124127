import React from 'react'
import DateTimePickerDialog from '../CallSection/DateTimePickerDialog'

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    weekday: 'long', // Include the full day name (e.g., Sunday, Monday)
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};
const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};


const SessionCard = ({session}) => {
  return (
    <div className="w-full max-w-md bg-gray-800 text-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4">
        <div className="flex items-center justify-between mb-5">
          <div className="flex items-center">
            <div className="w-12 h-12 rounded-full overflow-hidden mr-4">
              <img src={session?.author_image} alt="Creator" className='w-full h-full object-cover rounded-full' />
            </div>
            <div>
              <h3 className="text-lg font-semibold">{session.title}</h3>
              <p className="text-sm text-gray-400">with {session?.author}</p>
            </div>
          </div>
          
        </div>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-400">Session ID:</span>
            <span className="text-sm">{session?.session_id}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-400">Booked Date:</span>
            <span className="text-sm text-yellow-400 font-[600]">{formatDate(session?.booked_date)}</span>
          </div>
          <div className="flex justify-between ">
            <span className="text-sm font-medium text-gray-400">Timing:</span>
            <span className="text-sm text-yellow-400 font-[600]">{formatTime(session?.booked_date)}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-400">Order ID:</span>
            <span className="text-sm">{session?.order_id}</span>
          </div>
        </div>
      </div>
      <div className="px-6 py-4 bg-gray-700 flex justify-between items-center">
        
        <a 
          href={session.link}
          className="px-4 py-2 bg-green-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
        >
          Join Session
        </a>
        <DateTimePickerDialog title="Reschedule Session" buttonText="Reschedule" sessionDate={session?.booked_date} isReschedule={true} rescheduleFor={session?.session_id} dynamicPricing={false} id={session?.item_id}>
          
        <button 
          
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
        >
          Reschedule
        </button>
        </DateTimePickerDialog >
      </div>
    </div>
  )
}

export default SessionCard