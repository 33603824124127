import React from 'react'
import CourseCard from './CourseCard'
import { NewCourseCard } from './NewCourseCard'

const MyCourses = ({courses}) => {
  console.log(courses)
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
      {courses?.map((course) => {
        return (
          // <CourseCard key={course._id} course={course}/>
          <NewCourseCard key={course._id} course={course}/>
        )
      })}
    </div>
  )
}

export default MyCourses